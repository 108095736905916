<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-n3">a) Which of the following solutions is basic?</p>

      <v-radio-group
        v-model="inputs.multipleChoiceAnswer"
        class="ml-6 mb-0"
        :disabled="!allowEditing"
      >
        <v-radio v-for="option in options" :key="option.value" class="my-1" :value="option.value">
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-2">b) I think this because:</p>

      <p class="mb-n2">
        <s-textarea v-model="inputs.explainedResponse" outlined />
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import STextarea from '@/common/components/STextarea.vue';

export default {
  name: 'Question512',
  components: {
    STextarea,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        multipleChoiceAnswer: null,
        explainedResponse: null,
      },
      options: [
        {text: 'One with $\\ce{pH}$ = 5.0', value: 'pHFive'},
        {text: 'One with $\\ce{pH}$ = $\\ce{pOH}$', value: 'neutral'},
        {text: 'One with $\\ce{pOH}$ = 5.0', value: 'pOHFive'},
        {text: 'One with $\\ce{pOH}$ = 12.0', value: 'pOHTwelve'},
        {text: 'None of the above are basic', value: 'none'},
      ],
    };
  },
};
</script>
